<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Provider
      <router-link slot="right" to="/" class="button"> Dashboard </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">Upload and manage artifacts provided by the internal Artifact handler of the Connector.</p>
          <p>Contract Offers attached can also be viewed at the <router-link to="/pef/pap">Policy Administration Point</router-link>.</p>
        </em>
      </card-component>
      <card-component
        title="Artifacts"
        class="has-table has-mobile-sort-spaced"
        icon="file-document-multiple"
        headerColor="info"
      >
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="artifactsData"
        >
          <b-table-column
            label="Artifact ID"
            field="artifactId"
            sortable
            v-slot="props"
          >
            {{ props.row.artifactId }}
          </b-table-column>
          <b-table-column label="Title" field="title" sortable v-slot="props">
            {{ props.row.title }}
          </b-table-column>
          <b-table-column
            label="Created"
            field="created"
            sortable
            v-slot="props"
          >
            <template v-if="props.row.created">
              {{ props.row.created }}
            </template>
            <template v-else> - </template>
          </b-table-column>
          <b-table-column
            label="File name"
            field="filename"
            sortable
            v-slot="props"
          >
            <template v-if="props.row.filename">
              {{ props.row.filename }}
            </template>
            <template v-else> - </template>
          </b-table-column>
          <b-table-column
            label="Contract Offer"
            field="contractOffer"
            sortable
            v-slot="props"
          >
            <template v-if="props.row.contractOffer">
              <b-icon
                icon="file-search"
                type="is-info"
                class="is-clickable"
                @click.native="contractOfferModal = {isActive: true, offer: props.row.contractOffer}"
              >
              </b-icon>
            </template>
            <template v-else> - </template>
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-icon
              icon="delete"
              type="is-danger"
              class="is-clickable"
              @click.native="deleteArtifact(props.row.artifactId)"
            >
            </b-icon>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No artifacts present...</p>
            </div>
          </section>
        </b-table>
      </card-component>
      <card-component
        title="Upload new artifact"
        icon="upload"
        headerColor="success"
      >
        <form @submit.prevent="addArtifact">
          <b-field label="Title" horizontal>
            <b-input
              v-model="newArtifact.title"
              placeholder="Artifact title"
              required
            />
          </b-field>
          <b-field label="Description" horizontal>
            <b-input
              v-model="newArtifact.description"
              placeholder="Artifact description"
              required
            />
          </b-field>
          <b-field
            label="Artifact"
            horizontal
            class="file is-primary"
            :class="{ 'has-name': !!newArtifact.file }"
          >
            <b-upload v-model="newArtifact.file" class="file-label">
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Click to upload</span>
              </span>
              <span class="file-name" v-if="newArtifact.file">
                {{ newArtifact.file.name }}
              </span>
            </b-upload>
          </b-field>
          <b-field label="Artifact ID" horizontal>
            <b-input
              v-model="newArtifact.artifactId"
              placeholder="Artifact ID (URI) (optional, will be generated if not provided)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
            />
          </b-field>
          <b-field label="Contract Offer" message="Optional" horizontal>
            <b-input
              v-model="newArtifact.offer"
              type="textarea"
              :placeholder="contractPlaceholder"
              rows="10"
            />
            <b-button @click="createReadOffer">Generic read access</b-button>
          </b-field>
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Upload artifact</b-button
                >
              </div>
              <div class="control">
                <b-button
                  type="is-primary is-outlined"
                  @click="
                    () => {
                      newArtifact = {
                        title: null,
                        description: null,
                        artifactId: null,
                        offer: null,
                        file: null,
                      };
                    }
                  "
                  >Reset</b-button
                >
              </div>
            </b-field>
          </b-field>
        </form>
      </card-component>
      <b-modal :active.sync="contractOfferModal.isActive" has-modal-card>
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Contract Offer</p>
                </header>
                <section class="modal-card-body">
                  <vue-json-editor v-model="contractOfferModal.offer" :modes="['view', 'text']" mode="view" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="contractOfferModal.isActive = false">Close</button>
                </footer>
            </div>
        </b-modal>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'ArtifactProvider',
  components: {
    HeroBar,
    CardComponent,
    TitleBar,
    vueJsonEditor
  },
  data () {
    return {
      isLoading: false,
      paginated: true,
      contractOfferModal: {
        isActive: false,
        offer: null
      },
      contractPlaceholder: `{
  "@type": "ids:ContractOffer",
  "@id": "https://w3id.org/idsa/autogen/contractOffer/00000000-0000-0000-0000-000000000000",
  ...
}`,
      artifacts: [],
      newArtifact: {
        title: null,
        description: null,
        artifactId: null,
        offer: null,
        file: null
      },
      interval: null
    }
  },
  computed: {
    titleStack () {
      return ['Artifact Handling', 'Provider']
    },
    artifactsData () {
      return this.artifacts.map((artifact) => {
        var created = artifact['ids:created']?.['@value']
        if (created) {
          created = moment(created).tz(moment.tz.guess()).format('DD-MM-YYYY HH:mm:ss z')
        }
        return {
          artifactId: artifact['@id'],
          title: artifact['ids:title']?.[0]?.['@value'],
          contractOffer: artifact['ids:contractOffer']?.[0],
          filename: artifact['ids:representation']?.[0]?.['ids:instance']?.[0]?.['ids:fileName'],
          created: created
        }
      })
    },
    ...mapState(['api'])
  },
  async created () {
    this.interval = setInterval(this.getArtifacts, 30000)
    await this.getArtifacts()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    async getArtifacts () {
      try {
        const artifacts = await this.api.get('/artifacts/provider')
        this.artifacts = artifacts.data
      } catch (e) {
        clearInterval(this.interval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.interval)
            this.getArtifacts()
            this.interval = setInterval(this.getArtifacts, 30000)
          }
        })
        console.log(e)
      }
    },
    async addArtifact () {
      const formData = new FormData()
      formData.append('artifact', this.newArtifact.file)
      formData.append('title', this.newArtifact.title)
      formData.append('description', this.newArtifact.description)
      if (this.newArtifact.artifactId) {
        formData.append('artifactId', this.newArtifact.artifactId)
      }
      if (this.newArtifact.offer) {
        formData.append('contractOffer', this.newArtifact.offer)
      }

      try {
        await this.api.post('/artifacts/provider', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.newArtifact = {
          title: null,
          description: null,
          artifactId: null,
          offer: null,
          file: null
        }
        await this.getArtifacts()
        this.$buefy.toast.open({
          message: 'Artifact successfully uploaded',
          type: 'is-success',
          position: 'is-bottom'
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    async deleteArtifact (artifactId) {
      this.$buefy.dialog.confirm({
        title: 'Delete artifact',
        message: `Are you sure you want to permanently delete the artifact ${artifactId}?`,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.api.delete(
              `/artifacts/provider/${encodeURIComponent(artifactId)}`
            )
            await this.getArtifacts()
          } catch (e) {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              position: 'is-bottom'
            })
            console.log(e)
          }
        }
      })
    },
    createReadOffer () {
      this.newArtifact.offer = `{
  "@context" : {
    "ids" : "https://w3id.org/idsa/core/",
    "idsc" : "https://w3id.org/idsa/code/"
  },
  "@type" : "ids:ContractOffer",
  "@id" : "https://w3id.org/idsa/autogen/contractOffer/${this.uuidv4()}",
  "ids:permission" : [ {
    "@type" : "ids:Permission",
    "@id" : "https://w3id.org/idsa/autogen/permission/${this.uuidv4()}",
    "ids:action" : [ {
      "@id" : "https://w3id.org/idsa/code/USE"
    }, {
      "@id" : "https://w3id.org/idsa/code/READ"
    } ]
  } ],
  "ids:contractStart" : {
    "@value" : "${new Date().getFullYear()}-01-01T00:00:00.000+00:00",
    "@type" : "http://www.w3.org/2001/XMLSchema#dateTimeStamp"
  },
  "ids:contractEnd" : {
    "@value" : "${new Date().getFullYear()}-12-31T00:00:00.000+00:00",
    "@type" : "http://www.w3.org/2001/XMLSchema#dateTimeStamp"
  }
}`
    },
    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0
          const v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    }

  }
}
</script>
